import React from 'react'
import BasePage from './BasePage'
import {New} from '../types/news'
import NewsApi from '../api/NewsApi'
import {Link} from 'react-router-dom'
import routes from '../routes/routes'
import {HOST} from '../constants/hosts'
import defaultImage from '../resources/default_image.png'
import HeadMetaComponent from '../components/HeadMetaComponent'

type Props = {
    history: any
    match: any
}
type State = {
    id: number|string
    news?: New
}


export default class NewsDetailedPage extends BasePage<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            id: this.getId(),
        }
    }

    protected getHeaderTabKey(): string {
        return 'news'
    }

    protected getPageTitle(): string {
        return 'Новости - Центр трансфера технологий'
    }



    private getId(): number {
        return this.props.match.params.id
    }

    protected getVkLink(): string {
        let vk = "https://vk.com/share.php?url=https://permctt.ru/news/"  + this.getId()
        return vk
    }

    protected getOkLink(): string {
        let ok = "https://connect.ok.ru/offer?url=https://permctt.ru/news/"  + this.getId()
        return ok
    }

    componentDidMount(): void {
        this.load()
    }

    protected load() {
        NewsApi.getNewsDetailed(this.state.id).then(news => this.setState({news}))
    }

    renderBody() {
        const {news} = this.state

        return <div className="row">
            <HeadMetaComponent
                title={news?.title_meta || this.getPageTitle()}
                description={news?.description_meta}
                keywords={news?.keywords_meta}
            />
            <div className="col-xs-12 col-sm-12 col-md-12 col-xxl-12">
                <section className="mt-4 kartochka knews">
                    <article className="mb-4">
                        <div className="article">
                            <div className="kn">
                                {
                                    news?.news_categories && news.news_categories.map(
                                        cat => <div className="a-firma float-end mb-2 tag-news">
                                            <Link to={routes.newsPage.path.replace(':tag', cat.alt_name)}>{cat.title}</Link>
                                        </div>,
                                    )
                                }

                                <div className="clr clr-kn-h1"/>
                                <h1 className="mt-1 mb-5">{news?.title}</h1>

                                <div className="clr"/>
                                <div className="news-img mb-5">
                                    <img src={news?.logo ? `${HOST}${news.logo}` : defaultImage}/>
                                </div>

                                <div dangerouslySetInnerHTML={{ __html: news?.full_story || '' }}/>

                                <div className="clr"/>
                                <div className="a-date k-dp mb-4 mt-4 float-start">
                                    <i className="fa fa-clock-o" aria-hidden="true"/> {news?.date_creation_dd}
                                </div>

                                <div className="kn-soc k-dp mb-4 mt-4 float-end">
                                    <span className="shared">Поделиться новостью:</span>
                                    <a href={this.getVkLink()} target="_blank"><i className="fa fa-vk" aria-hidden="true"/></a>
                                    <a href={this.getOkLink()} target="_blank"><i className="fa fa-odnoklassniki" aria-hidden="true"/></a>
                                </div>
                                <div className="clr"/>
                            </div>
                            <h5 className="related mt-4 mb-4">Похожие новости</h5>

                            <div className="row news inst">
                                {
                                    !!news?.related && news.related.map(
                                        related => <div className="relnews">
                                            <div className="article">
                                                <a href={routes.newsDetailedPage.path.replace(':id', related.alt_name || related.id_news.toString())} className="stretched-link">
                                                    <div className="a-date float-start">
                                                        <i className="fa fa-clock-o"
                                                           aria-hidden="true"/> {related?.date_creation_dd}
                                                    </div>
                                                    <div className="a-univ-container">
                                                    {
                                                        news.news_categories && news.news_categories.map(
                                                            cat => <div className="float-end a-univ">
                                                                <Link
                                                                    to={routes.newsPage.path.replace(':tag', cat.alt_name)}>{cat.title}</Link>
                                                            </div>,
                                                        )
                                                    }
                                                    </div>
                                                    <div className="clr"/>
                                                    <div className="inst-img mt-2 d-flex">
                                                        <img
                                                            src={related?.logo ? `${HOST}${related.logo}` : defaultImage}
                                                            className="d-block m-auto mh-100 mw-100"
                                                        />
                                                    </div>
                                                    <h2 className="mt-1 mb-4">{related?.title}</h2>

                                                    <div className="clr"/>
                                                    <p dangerouslySetInnerHTML={{ __html: related?.short_story || '' }}/>
                                                    <div className="clr"/>
                                                </a>
                                            </div>
                                        </div>,
                                    )
                                }
                            </div>
                        </div>
                    </article>
                </section>
            </div>
        </div>
    }
};
