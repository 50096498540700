import React, {useState} from 'react'
import {toast} from 'react-toastify'
import routes from '../routes/routes'
import {Link} from 'react-router-dom'

type Props = {
    isOpen: boolean
    onClose: any
    onSubmit: any
}

export default function SubscribeModal(props: Props) {
    const [form, setForm] = useState({
        name: '',
        email: '',
        terms: false,
    })

    const handleChange = (name: string) => (event: any) => {
        setForm({
            ...form,
            [name]: event.target.value,
        })
    }

    const handleCheck = (event: any) => {
        setForm({
            ...form,
            terms: event.target.checked,
        })
    }

    const handleSubmit = (event: any) => {
        event.preventDefault()
        if (!form.terms) {
            toast.error('Вы должны принять условия')
            return
        }
        if (form.name === undefined || form.name.length <= 3 || form.name.length >= 200) {
            toast.error('Проверьте корректность ФИО')
            return
        }
        if (!form.email
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            )) {
            toast.error('Проверьте корректность Email')
            return
        }
        props.onSubmit(form.email, form.name)
    }

    return <div id="modal" style={{display: props.isOpen ? 'block' : 'none'}}>
        <div className="overlay-modal" onClick={props.onClose}/>
        <div className="aside-b subscribe">
            <form>
                <a className="close-subscribe" href="#" onClick={props.onClose}>Х</a>
                <h4>Подписка</h4>
                <div className="form mb-2">
                    <input type="text" className="form-control" id="floatingInputName" placeholder="ФИО"
                           onChange={handleChange('name')} value={form.name}
                    />
                </div>
                <div className="form mb-3">
                    <input type="email" className="form-control" id="floatingInputEmail" placeholder="Email"
                           onChange={handleChange('email')} value={form.email}
                    />
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                           onChange={handleCheck} checked={form.terms}
                    />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                        Согласен на <Link to={routes.rulesPage.path}>обработку персональных данных</Link>
                    </label>
                </div>
                <div className="clr"/>
                <button type="submit" className="btn btn-primary mt-2" onClick={handleSubmit}>Подписаться</button>
                <div id="" className="form-text mt-4 mb-0">
                    Раз в сутки отправим письмо с подборкой
                    <br/> новых мер поддержки по Вашему фильтру.
                    <br/>Без спама.
                </div>

            </form>
        </div>
    </div>
}
