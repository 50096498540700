import {toast} from 'react-toastify'
import {inspect} from 'util'
import {HOST} from '../constants/hosts'
import {errors} from './Errors'

export type BaseResponse = {
    status: string
    data?: any
    error_msg?: string[]

    count?: number
    min_amount?: number
    max_amount?: number
    userid?: number
}

const SUCCESS_STATUS = 'successful'


export abstract class BaseApi {

    private static getUrl(path: string): URL {
        return new URL(`${HOST}${path}`)
    }

    private async requestFetch(url: URL, type: string, data?: object, initParams?: object): Promise<BaseResponse> {
        // @ts-ignore
        return fetch(new Request(url, {
            method: type,
            withCredentials: true,
            credentials: 'include', // todo: same-origin, not include
            ...initParams,
        }))
            .then(response => {
                if (!response.ok) {
                    toast.error(`Сервер ответил ошибкой ${response.status}`)
                    throw `${response.status} ${response.statusText} ${response.url}`
                }
                return response.json().then(response_body => {
                    if (response_body.status !== SUCCESS_STATUS) {
                        if (response_body.error_msg instanceof Array) {
                            response_body.error_msg.forEach((error => {
                                toast.error(`Ошибка: ${error in errors ? errors[error] : error}`)
                            }))
                        }
                        throw `${response_body.status} (${response_body.error_msg})`
                    }
                    return response_body
                })
            })
            .catch(error => {
                // toast.error(`Ошибка в запросе: ${error} \n${url}`)
                console.error(`Error in ${type} request to ${url} with ${inspect(data)}:`, error)
                throw error
            })
    }

    protected async getRequest(path: string, params?: object): Promise<BaseResponse> {
        params = params || {}
        let url = BaseApi.getUrl(path)
        // @ts-ignore
        Object.keys(params).forEach(key => {
            // @ts-ignore
            if (params[key] === undefined) {
                return
            }
            // @ts-ignore
            if (Array.isArray(params[key])) {
                // @ts-ignore
                params[key].map(value => url.searchParams.append(key, value))
            } else {
                // @ts-ignore
                url.searchParams.append(key, params[key])
            }
        })

        return this.requestFetch(url, 'GET', params)//.then(response => response.data)
    }

    protected async postRequest(path: string, data: object): Promise<BaseResponse> {
        return this.requestFetch(BaseApi.getUrl(path), 'POST', data,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
        //.then(response => response.data)
    }

    protected async postFormDataRequest(path: string, data: object): Promise<BaseResponse> {
        const formData = new FormData()
        Object.keys(data).forEach(key => {
            // @ts-ignore;
            formData.append(key, data[key])
        })

        return this.requestFetch(BaseApi.getUrl(path), 'POST', data,
            {
                body: formData,
            })
    }

    protected async putRequest(path: string, data: object): Promise<any> {
        return this.requestFetch(BaseApi.getUrl(path), 'PUT', data, {body: JSON.stringify(data)})
            .then(response => response.data)
    }

    protected async deleteRequest(path: string): Promise<any> {
        return this.requestFetch(BaseApi.getUrl(path), 'DELETE')
            .then(response => response.data)
    }
}
