import React from 'react'
import BasePage from './BasePage'
import SupportApi from '../api/SupportApi'
import {Support} from '../types/supports'
import arrowIcon2 from '../resources/arrow2.png'
import { Link } from 'react-router-dom'
import routes from '../routes/routes'
import HeadMetaComponent from '../components/HeadMetaComponent'

type Props = {
    history: any
    match: any
}
type State = {
    id: number
    support?: Support
}


export default class SupportPage extends BasePage<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            id: this.getId(),
        }
    }

    protected getHeaderTabKey(): string {
        return 'supports'
    }

    protected getPageTitle(): string {
        return 'Меры поддержки - Центр трансфера технологий'
    }

    private getId(): number {
        return Number.parseInt(this.props.match.params.id)
    }

    componentDidMount(): void {
        this.load()
    }

    protected load() {
        const id = this.state.id
        SupportApi.getSupport(id).then(
            support => this.setState({support}),
        )
    }
    

    renderBody() {
        const {support} = this.state

        return <div className="row">
            <HeadMetaComponent
                title={support?.title_meta || this.getPageTitle()}
                description={support?.description_meta}
                keywords={support?.keywords_meta}
            />
            <div className="col-xs-12 col-sm-12 col-md-12 col-xxl-12">
                <section className="mt-4 kartochka">

                    <article className="mb-4">

                        <div className="article">
                            <div className="float-end">
                            {
                                support?.support_forms && support.support_forms.map(
                                    form => <div className="a-forma meraforma float-end mfm"><span>{form.support_form_name}</span></div>,
                                )
                            }
                            </div>

                            <h1 className="mb-4 mt-1">{support?.support_name_manual || support?.support_name}</h1>

                            {
                                support?.institute_name && support?.id_institutes && <div className="a-firma mb-2">
                                  <Link to={routes.institutePage.path.replace(':id', support.id_institutes.toString())} className="stretched-link">{support.institute_name}</Link>
                                </div>
                            }

                            <div className="clr"/>
                            {
                                support?.no_finance
                                    ? <div className="a-cena mt-4 mb-4 amount-mera">Нефинансовая мера поддержки</div>
                                    : <div className="a-cena mt-4 mb-4 amount-mera">до {support?.amount}<u>тыс. руб.</u>
                                    </div>
                            }
                            {
                                !!support?.remained_count &&
                              <div className="a-srok mt-4 mb-4">
                                  <i className="fa fa-clock-o" aria-hidden="true"/>
                                  <u>осталось {support?.remained_count} {support?.remained_days}</u>
                              </div>
                            }

                            <div dangerouslySetInnerHTML={{ __html: support?.description || '' }} />

                            {/*<p><b className="k-srok">Срок реализации:</b> 1 год</p>*/}

                            <div className="homebtn mt-5">
                                <a href={support?.site} target="_blank" rel="noopener noreferrer">
                                    Подробнее на странице
                                    <img className="arrow2" src={arrowIcon2}/>
                                </a>
                            </div>

                            <div className="a-date k-dp">
                                <i className="fa fa-clock-o" aria-hidden="true"/>
                                Дата публикации
                                <br/>
                                {support?.date_creation_dd}
                            </div>
                            {support?.date_end_dd &&
                            <div className="a-date k-do">
                                <i className="fa fa-fire" aria-hidden="true"/>
                                Дата окончания приёма
                                <br/>
                                {support?.date_end_dd}
                            </div>}
                        </div>


                    </article>


                </section>

            </div>
        </div>
    }
};
