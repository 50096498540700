import React from 'react'
import BasePage from './BasePage'
import {Support, SupportForm} from '../types/supports'
import InstituteApi from '../api/InstituteApi'
import {Institute} from '../types/institutes'
import {HOST} from '../constants/hosts'
import defaultImage from '../resources/default_image.png'
import routes from '../routes/routes'
import {Link} from 'react-router-dom'
import arrow from '../resources/arrow.png'
import HeadMetaComponent from '../components/HeadMetaComponent'

type Props = {
    history: any
    match: any
}
type State = {
    id: number
    institute?: Institute
    supports?: Support[]
    supportForms?: SupportForm[]
}


export default class InstitutePage extends BasePage<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            id: this.getId(),
        }
    }

    protected getHeaderTabKey(): string {
        return 'institutes'
    }

    protected getPageTitle(): string {
        return 'Институты поддержки - Центр трансфера технологий'
    }

    private getId(): number {
        return Number.parseInt(this.props.match.params.id)
    }

    componentDidMount(): void {
        this.load()
    }

    protected load() {
        const id = this.state.id
        InstituteApi.getInstitute(id).then(institute => this.setState({institute}))
        InstituteApi.getSupportsForInstitute(id).then(supports => this.setState({supports}))
        InstituteApi.getSupportsFormsForInstitute(id).then(supportForms => this.setState({supportForms}))
    }

    renderBody() {
        const {institute, supports, supportForms} = this.state

        return <div className="row">
            <HeadMetaComponent
                title={institute?.title_meta || this.getPageTitle()}
                description={institute?.description_meta}
                keywords={institute?.keywords_meta}
            />
            <div className="col-xs-12 col-sm-12 col-md-12 col-xxl-12">
                <section className="mt-4 kartochka ki">
                    <article className="mb-4">
                        <div className="article">
                            <div className="ki-img col-12 ki-img-up position-relative mb-4">
                                <div className="inst-img">
                                    <img src={institute?.logo ? `${HOST}/${institute.logo}` : defaultImage}/>
                                </div>
                            </div>

                            <div className="ki-content col-12 col-md-8">
                                <div className="clr"/>
                                <h1 className="mt-2 mb-4">{institute?.institute_name}</h1>
                                {
                                    institute?.institution_forms &&
                                    institute.institution_forms.map(
                                        form => <div
                                            className="a-firma k-border mb-1">{form.institution_form_name}</div>,
                                    )
                                }

                                <div className="clr"/>
                                {
                                    !!institute?.site &&
                                  <div className="a-forma mb-4">
                                      <a href={institute.site} target="_blank" rel="noopener noreferrer">
                                          <i className="fa fa-globe" aria-hidden="true"/>
                                          {institute.site}
                                      </a>
                                  </div>
                                }

                                <div className="clr"/>
                                <div dangerouslySetInnerHTML={{ __html: institute?.description || '' }} />

                                {
                                    supportForms && supportForms.length > 0 && <div>
                                      <h2 className="mt-5 mb-4">Формы поддержки</h2>
                                        {
                                            supportForms.map(
                                                form => <Link to={`${routes.institutesPage.path}?support_forms=${form.id_support_forms}`}>
                                                    <div className="a-firma k-border mb-4">
                                                    {form.support_form_name}
                                                </div>
                                                </Link>,
                                            )
                                        }
                                  </div>
                                }

                                {
                                    supports && supports.length > 0 &&
                                  <h2 className="mt-3 mb-4">Актуальные меры поддержки</h2>
                                }
                            </div>
                            <div className="ki-content2 col-12 col-lg-8">
                                {
                                    supports && supports.map(support => <article className="mb-5 mt-1">
                                            <div className="article">
                                                <Link
                                                    to={routes.supportPage.path.replace(':id', support.id_supports.toString())}
                                                    className="stretched-link">
                                                    <div className="a-date float-start">
                                                        <i className="fa fa-clock-o" aria-hidden="true"/> {support.date_creation}
                                                    </div>
                                                    {
                                                        support?.support_forms && support.support_forms.map(
                                                            form => <div
                                                                className="a-forma float-end">{form.support_form_name}</div>,
                                                        )
                                                    }
                                                    <div className="clr"/>
                                                    <h2 className="mt-2 mb-4">{support.support_name_manual || support.support_name}</h2>
                                                    <div className="float-end">
                                                        <div className="a-cena mb-3"><b>{support.amount}</b><u>тыс. руб.</u>
                                                        </div>
                                                        {support?.remained_count &&
                                                        <div className="a-srok float-end">
                                                            <i className="fa fa-clock-o" aria-hidden="true"/>
                                                            <u>{support?.remained_count} {support?.remained_days}</u>
                                                            <img className="arrow" src={arrow}/>
                                                        </div>}
                                                    </div>
                                                    <div className="clr"/>
                                                </Link>
                                            </div>
                                            <div className="float-start float-start-url">
                                                <div className="a-firma">
                                                    <a href="#" className="stretched-link actual-instit">{institute?.institute_name}</a>
                                                </div>
                                            </div>
                                        </article>,
                                    )
                                }
                            </div>

                            <div className="ki-img col-12 col-md-3 ki-img-down">
                                <div className="inst-img">
                                    <img src={institute?.logo ? `${HOST}/${institute.logo}` : defaultImage}/>
                                </div>
                            </div>

                        </div>


                    </article>


                </section>

            </div>
        </div>
    }
};
