import React from 'react'
import BasePage from './BasePage'
import {HOST} from '../constants/hosts'
import ReactPaginate from 'react-paginate'
import defaultImage from '../resources/default_image.png'
import {Link} from 'react-router-dom'
import routes from '../routes/routes'
import {News, NewsCategory} from '../types/news'
import NewsApi from '../api/NewsApi'

type Props = {
    history: any
    match: any
}
type State = {
    news?: News
    page: number
    pageSize: number

    categories?: NewsCategory[]
    categoriesMapping?: object

    // filters: {
    //     tag?: string
    // }
}


export default class NewsPage extends BasePage<Props, State> {
    constructor(props: Props) {
        super(props)
        const params = new URLSearchParams(this.props.history.location.search)
        this.state = {
            page: parseInt(params.get('page') || '1'),
            pageSize: parseInt(params.get('page_size') || '9'),

            // filters: {
            //     tag: this.props.match.params.tag,
            // },
        }
    }

    protected getHeaderTabKey(): string {
        return 'news'
    }

    protected getPageTitle(): string {
        return 'Новости - Центр трансфера технологий'
    }

    getTag = () => this.props.match.params.tag

    componentDidMount(): void {
        this.load()
        this.loadFilters()
    }

    componentDidUpdate(prevProps, prevState, snapshot): void {
        console.log('UPDATED', this.props, prevProps, prevState, snapshot)
        if (this.getTag() !== prevProps.match.params.tag) {
            this.load()
        }
    }

    protected load() {
        NewsApi.getNews(this.state.page, {
            maxelem: this.state.pageSize,
            news_categories: this.getTag(),
        }).then(news => this.setState({news}))
    }

    protected loadFilters() {
        NewsApi.getNewsCategories().then(
            dataList => {
                const mapping = {}
                dataList && dataList.forEach(x => {
                    // @ts-ignore
                    mapping[x.institution_form_name] = x.id_institution_forms
                })
                this.setState({categoriesMapping: mapping, categories: dataList})
            },
        )
    }

    setQueryParams = () => {
        const history = this.props.history

        let searchPath = `?page=${this.state.page}&page_size=${this.state.pageSize}`
        history.push({
            pathname: history.location.pathname,
            search: searchPath,
        })
    }

    handlePageChange = (event: any) => {
        this.setState({page: event.selected + 1}, () => {
            this.load()
            this.setQueryParams()
        })
    }

    renderBody() {
        const {news, page, pageSize, categories} = this.state

        let pageCount = 0
        if (news) pageCount = Math.ceil(news.count / pageSize)

        return <div className="row">
            <h1 className="h1-st mb-4 mt-4">Новости</h1>
            <div className="col-12">
                <section className="mb-4 news">
                    <div className="tags mb-5">
                        <a href="/news/">Все новости</a>
                        {
                            categories && categories.map(
                                cat => <Link to={routes.newsPage.path.replace(':tag', cat.alt_name)}>{cat.title}</Link>,
                            )
                        }
                    </div>

                    <div className="row inst">

                        {
                            news?.list && news.list.map(
                                news => <div className="col-xs-12 col-md-6 col-xl-4 mb-5 mt-2">
                                    <div className="article">
                                        <Link to={routes.newsDetailedPage.path.replace(':id', news.alt_name || news.id_news.toString())} className="stretched-link">
                                            <div className="a-date float-start">
                                                <i className="fa fa-clock-o" aria-hidden="true"/> {news.date_creation}
                                            </div>
                                            <div className="a-univ-container">
                                            {/*{*/}
                                            {/*    news.news_categories && news.news_categories.map(*/}
                                            {/*        cat => <div className="float-end a-univ">{cat.title}</div>,*/}
                                            {/*    )*/}
                                            {/*}*/}
                                            {
                                                news.news_categories && news.news_categories.map(
                                                    cat => <div className="float-end a-univ">
                                                        <Link to={routes.newsPage.path.replace(':tag', cat.alt_name)}>{cat.title}</Link>
                                                    </div>,
                                                )
                                            }
                                            </div>
                                            <div className="clr"/>
                                            <div className="inst-img d-flex-img mt-3">
                                                <img
                                                    src={news.logo ? `${HOST}${news.logo}` : defaultImage}
                                                    className="d-block m-auto mh-100 mw-100"
                                                />
                                            </div>

                                            <h2 className="mt-4 mb-4">{news.title}</h2>

                                            <div className="clr"/>
                                            <p dangerouslySetInnerHTML={{ __html: news.short_story || '' }}/>
                                            <div className="clr"/>
                                        </Link>
                                    </div>
                                </div>,
                            )
                        }

                        <div className="clr"/>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel={<i className="fa fa-angle-right" aria-hidden="true"/>}
                            onPageChange={this.handlePageChange}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            initialPage={page - 1}
                            previousLabel={<i className="fa fa-angle-left" aria-hidden="true"/>}
                            // @ts-ignore
                            renderOnZeroPageCount={null}
                            containerClassName="pagination"
                            pageClassName="page-item"
                            previousClassName="page-item"
                            nextClassName="page-item"
                            breakClassName="page-item"
                            pageLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            breakLinkClassName="page-link"
                            activeLinkClassName="page-link-active"
                        />
                    </div>
                </section>
            </div>
        </div>
    }
};
