import React from 'react'
import UserApi from '../api/UserApi'

export default class Authenticator extends React.Component<{}, {}> {
    componentDidMount(): void {
        UserApi.getId().then(r => {
            localStorage.setItem('userId', r.userid ? r.userid.toString() : '')
        })
    }

    render() {
        return <div/>
    }
}