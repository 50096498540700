import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import UserApi from '../api/UserApi'
import {toast} from 'react-toastify'

type Props = {
    footer?: boolean
}

export default function Feedback(props: Props) {
    const history = useHistory()

    const [form, setForm] = useState({
        name: undefined,
        email: undefined,
        subject: undefined,
        text: undefined,
    })

    const handleChange = (name: string) => (event: any) => {
        setForm({
            ...form,
            [name]: event.target.value,
        })
    }

    const handleSubmit = (event: any) => {
        event.preventDefault()
        UserApi.sendFeedback(form.name, form.email, form.subject, form.text).then(() => {
            toast.success('Ваше обращение отправлено!')
        })
    }

    if (props.footer) {
        return <form onSubmit={handleSubmit}>
            <h5>Обратная связь</h5>
            <div className="col-12 col-lg-6">
                <div className="form mb-2">
                    <input type="text" className="form-control" id="floatingInput"
                           placeholder="Имя" onChange={handleChange('name')}/>
                </div>
                <div className="form mb-2">
                    <input type="email" className="form-control" id="floatingInput"
                           placeholder="Email" onChange={handleChange('email')}/>
                </div>
                <div className="form mb-2">
                    <input type="text" className="form-control" id="floatingInput"
                           placeholder="Тема" onChange={handleChange('subject')}/>
                </div>
            </div>
            <div className="col-12 col-lg-6">
                <div className="form textarea-footer">
                    <textarea className="form-control" id="floatingInput" placeholder="Сообщение"
                              onChange={handleChange('text')}/>
                </div>
            </div>
            <div className="clr"/>
            <button type="submit" className="btn btn-primary mt-2">Отправить</button>
        </form>
    } else {
        return <form onSubmit={handleSubmit}>
            <h5>Обратная связь</h5>
            <div className="col-12 mt-5">
                <div className="form mb-4">
                    <input type="text" className="form-control" id="floatingInput"
                           placeholder="Имя" onChange={handleChange('name')}/>
                </div>
                <div className="form mb-4">
                    <input type="text" className="form-control" id="floatingInput"
                           placeholder="Компания" onChange={handleChange('subject')}/>
                </div>
                <div className="form mb-4">
                    <input type="email" className="form-control" id="floatingInput"
                           placeholder="Email" onChange={handleChange('email')}/>
                </div>
            </div>
            <div className="col-12 mb-4">
                <div className="form">
                    <textarea className="form-control" id="floatingInput" placeholder="Сообщение"
                              onChange={handleChange('text')}/>
                </div>
            </div>
            <button type="submit" className="btn btn-primary mt-2">Отправить</button>
        </form>
    }
}
