import React from 'react'
import BasePage from './BasePage'
import {
    ADDRESS_WITH_UNIVERSITY_NAME,
    EMAIL,
    NETWORKS_CONTACTS,
    PHONE,
    PHONE_LINK,
    SITE,
} from '../constants/contacts'
import Feedback from '../components/Feedback'

type Props = {}
type State = {}


export default class ContactsPage extends BasePage<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {}
    }

    protected getHeaderTabKey(): string {
        return 'contacts'
    }

    protected getPageTitle(): string {
        return 'Контакты - Центр трансфера технологий'
    }

    componentDidMount(): void {
        // this.load()
    }

    renderBody() {
        return <div className="row">
            <h1 className="h1-st mb-4 mt-4">Контакты</h1>

            <div className="col-xs-12 col-sm-12 col-md-12 col-xxl-12">
                <section className="kontakty">
                    <article className="mb-5">
                        <div className="row">

                            <div className="col-12 col-md-6 col-lg-6 mt-4">
                                <h4>Центр трансфера технологий<br/>Пермского Политеха</h4>
                                <p className="footadres">{ADDRESS_WITH_UNIVERSITY_NAME}</p>
                                <p className="foottel">Сайт: <a href={`https://${SITE}`}>{SITE}</a></p>
                                <p className="foottel">Телефон: <a href={PHONE_LINK}>{PHONE}</a></p>
                                <p className="footmail">E-mail: <a href={`mailto:${EMAIL}`}>{EMAIL}</a></p>
                                <div className="soc mt-5 mb-3">
                                    {NETWORKS_CONTACTS}
                                </div>
                            </div> 

                            <div className="col-12 col-md-5 offset-md-1 col-lg-5 mt-4">
                                <Feedback/>
                            </div>

                        </div>
                    </article>
                </section>
            </div>
        </div>
    }
};
