import React from 'react'
import {Helmet} from 'react-helmet'

type Props = {
    title: string
    description?: string
    keywords?: string
}
type State = {}


export default class HeadMetaComponent extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {}
    }

    render() {
        const {title, description, keywords} = this.props
        return <Helmet>
            <title>
                {title}
            </title>
            {
                description
                    ? <meta name="description" content={description}/>
                    : <div/>
            }
            {
                keywords
                    ? <meta name="keywords" content={keywords}/>
                    : <div/>
            }
        </Helmet>
    }
}
