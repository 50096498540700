import React from 'react'
import BasePage from './BasePage'
import routes from '../routes/routes'
import HeadMetaComponent from '../components/HeadMetaComponent'
import roboHand from '../resources/bg.png'
import { Link } from 'react-router-dom'

type Props = {}
type State = {}


export default class MainPage extends BasePage<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {}
    }

    protected getHeaderTabKey(): string {
        return 'main'
    }

    protected getPageTitle(): string {
        return 'Центр трансфера технологий'
    }

    componentDidMount(): void {
        // this.load()
    }

    // protected renderBody(): React.ReactElement {
    //     return <></>
    // }

    protected renderBody() {
        return <div className="mt-4 mb-5">
            <div className="row">
                <div className="col-xs-12 col-sm-8 col-lg-9 col-xl-8 home">
                    <div className="home-content">
                        <h1 className="mb-5">Центр<br/>трансфера<br/>технологий<br/>Пермского<br/>Политеха</h1>
                        <h4 className="mb-5">Технологические&nbsp;решения&nbsp;
                            <wbr/>для&nbsp;бизнеса
                        </h4>
                        <div className="kontakty">
                            <Link to={routes.contactsPage.path} className="btn btn-primary napisat">Написать в ЦТТПП</Link>
                            <Link to={routes.supportsPage.path} className="btn btn-primary prezentaciya">Презентация ЦТТПП</Link>
                        </div>
                    </div>
                </div>
                <div className="col-4 col-xl-4 col-lg-3  home-img d-none d-sm-block">
                    <img src={roboHand} className="float-end" width="200%"/>
                </div>
            </div>
        </div>
    }
};
