import {BaseApi} from './BaseApi'
import {Institute, InstituteForm, Institutes} from '../types/institutes'
import {Search} from '../types/common'
import {Support, SupportForm} from '../types/supports'


class InstituteApi extends BaseApi {

    public async getInstitutes(page: number, search?: Search): Promise<Institutes> {
        return this.postRequest(`/getinstitutes/${page}`, {search} || {}).then(
            response => ({
                list: response.data,
                count: response.count || 0,
            }),
        )
    }

    public async getInstitute(id: number): Promise<Institute> {
        return this.getRequest(`/getinstitute/${id}`).then(
            response => response.data,
        )
    }

    public async getInstituteForms(): Promise<InstituteForm[]> {
        return this.getRequest(`/getinstitutionforms`).then(
            response => response.data,
        )
    }

    public async getSupportsForInstitute(id): Promise<Support[]> {
        return this.getRequest(`/getactualsupports/${id}`).then(
            response => response.data,
        )
    }

    public async getSupportsFormsForInstitute(id): Promise<SupportForm[]> {
        return this.getRequest(`/getinstitutionsupportforms/${id}`).then(
            response => response.data,
        )
    }

}

export default new InstituteApi()
