import React from 'react'
import {TabItem, TabsBase} from './TabsBase'
import logo from '../resources/logo.svg'
import {Link} from 'react-router-dom'
import routes from '../routes/routes'
import {EMAIL, NETWORKS, TELEGRAM, VK} from '../constants/contacts'


export default class HeaderMenu extends TabsBase {
    constructor(props) {
        super(props)
        const params = new URLSearchParams(document.location.search)
        this.state = {
            search: params.get('search') || undefined,
            mobileMenuIsOpen: false,
        }
    }

    protected getTabItems(): TabItem[] {
        return [
            new TabItem('main', 'О центре', routes.aboutPage.path, false),
            new TabItem('supports', 'Меры поддержки', routes.supportsPage.path, false),
            new TabItem('institutes', 'Институты поддержки', routes.institutesPage.path, false),
            new TabItem('news', 'Новости', routes.newsPageBase.path, false),
            new TabItem('statics', 'Команда', '/komanda', false),
            new TabItem('contacts', 'Контакты', routes.contactsPage.path, false),
        ]
    }

    renderTab(item: TabItem) {
        let className = ''
        if (this.getTabKey() == item.key) {
            className += 'selected-link'
        }
        if (item.disabled) {
            className += 'disabled-link'
        }
        return <li key={item.key}>
            <Link
                to={item.link}
                className={className}
            >
                {item.title}
            </Link>
        </li>
    }

    render() {
        const userId = localStorage.getItem('userId')

        const menuItems = <div className="row">
            <ul>
                {this.getTabItems().map(item => this.renderTab(item))}
            </ul>
        </div>

        const logoLink = <Link to={routes.mainPage.path}><img src={logo}/></Link>
        const main = <Link to={routes.mainPage.path} href="/"><i className="fa fa-home" aria-hidden="true"/>Главная</Link>
        const lkLink = userId ? <a href={routes.adminMainPage.path}>Личный кабинет</a> : <div/>
        const mailto = <Link to={routes.contactsPage.path}>Написать в центр</Link>
        const searchForm = <form className="search">
            <input type="text" placeholder="Поиск мер поддержки" value={this.props.search} onChange={this.props.onSearchInput}/>
            <button type="submit"><i className="fa fa-search" aria-hidden="true" onClick={this.props.onSearch}/></button>
        </form>
        const networks = NETWORKS

        return <>
            <header>
                <div className="container">
                    <div className="d-none d-md-block">
                        <div className="row">
                            <div className="col-2 col-sm-4 col-md-4 col-lg-4 col-xl-2 logo">
                                {logoLink}
                            </div>

                            <div className="col-5 col-sm-8 col-xl-5 col-xxl-6 col-lg-8 col-md-8 homebtn">
                                {main}
                                {searchForm}
                            </div>

                            <div
                                className="col-3 col-sm-7 col-xl-5 col-xxl-4 col-md-12 col-lg-12 lk float-end">
                                {lkLink}
                                {mailto}

                                <div className="soc float-end">
                                    {networks}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-block d-md-none">
                        <div className="row">
                            <div className="col-12">
                                <div className="logo float-start">
                                    {logoLink}
                                </div>

                                <div className="soc float-end">
                                    {networks}
                                </div>
                            </div>

                            <div className="col-12">
                                {searchForm}
                            </div>

                            <div className="col-9 lk">
                                {lkLink}
                                {mailto}
                            </div>

                            <div className="col-3 menubar">
                                <a href="#" id="menubarbtn" onClick={
                                    // @ts-ignore
                                    () => this.setState({mobileMenuIsOpen: !this.state.mobileMenuIsOpen})
                                }>
                                    <i className="fa fa-bars" aria-hidden="true"/></a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <nav className="d-none d-md-block">
                {menuItems}
            </nav>
            <div className="navmobile mb-4" id="navmobile" style={{
                // @ts-ignore
                display: this.state.mobileMenuIsOpen ? 'block' : 'none'
            }}>
                {menuItems}
            </div>
        </>
    }
}
