import React from 'react'
import BasePage from './BasePage'
import {ADDRESS, EMAIL, NETWORKS, PHONE, PHONE_LINK, SITE} from '../constants/contacts'
import Feedback from '../components/Feedback'

type Props = {}
type State = {}


export default class RulesPage extends BasePage<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {}
    }

    protected getHeaderTabKey(): string {
        return 'rules'
    }

    protected getPageTitle(): string {
        return 'Пользовательское соглашение - Центр трансфера технологий'
    }

    componentDidMount(): void {
        // this.load()
    }

    renderBody() {
        return <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-xxl-12">
                <section className="kontakty">
                    <article className="mb-5">
                        <br/>
                        <br/>
                        <h4>Согласие на обработку персональных данных (Пользовательское соглашение)</h4>

                        <p>
                            Настоящим, в соответствии с Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных
                            данных», Вы (далее — Пользователь) подтверждаете свое согласие на обработку Общества с
                            ограниченной ответственностью «Горные вентиляционные устройства» (далее – ООО «ГВУ»,
                            Оператор), юридический адрес: 614013, Пермский край, город Пермь, улица Академика Королева,
                            дом 21, офис 213 ваших персональных данных.
                        </p>
                        <p>
                            Под персональными данными подразумевается любая информация, относящаяся к прямо или косвенно
                            к
                            Вам, включая, но не ограничиваясь: фамилия, имя, отчество; дата рождения; место рождения,
                            адрес;
                            семейное положение; социальное положение; имущественное положение; образование; профессия;
                            доходы; ИНН, СНИЛС, контактная информация (телефон, адрес электронной почты), иные сведения,
                            предусмотренные типовыми формами и установленным порядком обработки.
                        </p>
                        <p>
                            Пользователь:
                            <ul>
                                <li>
                                    подтверждает, что все указанные им на Сайте: https://startstage.ru/ (далее – Сайт)
                                    персональные данные, обработка которых может быть осуществлена только с согласия
                                    субъекта
                                    персональных данных, принадлежат лично ему;
                                </li>
                                <li>
                                    подтверждает и признает, что им внимательно в полном объеме прочитано Соглашение,
                                    Политика
                                    в
                                    отношении обработки персональных данных (далее – Политика), содержание текста
                                    Соглашения и
                                    Политики и условия обработки персональных данных ему понятны;
                                </li>
                                <li>
                                    выражает свое согласие на обработку персональных данных без оговорок и ограничений.
                                    Моментом
                                    принятия согласия является маркировка (проставление галочки) соответствующего поля в
                                    форме и
                                    нажатие на кнопку для отправки данных на Сайте подтверждает, что, давая согласие, он
                                    действует
                                    свободно, своей волей и в своем интересе;
                                </li>
                                <li>
                                    Пользователь, принимая условия настоящего Соглашения, выражает свою
                                    заинтересованность и
                                    дает
                                    полное согласие, что обработка его персональных данных включает в себя любое
                                    действие или
                                    совокупность действий, совершаемых с использованием средств автоматизации или без
                                    использования
                                    таких средств с персональными данными, включая сбор, запись, систематизацию,
                                    накопление,
                                    хранение, уточнение (обновление, изменение), извлечение, использование, передачу
                                    (распространение, предоставление, доступ), обезличивание, блокирование, удаление,
                                    уничтожение
                                    персональных данных;
                                </li>
                                <li>
                                    Пользователь признает, что Оператор имеет право отказать в предоставлении услуг без
                                    обоснования причины такого отказа.
                                </li>
                            </ul>
                        </p>
                        <p>
                            Цель обработки персональных данных Пользователя — предоставление доступа Пользователю к
                            сервисам, информации и/или материалам, услугам, содержащимся на веб-сайте
                            https://startstage.ru/
                            и в рассылке.
                        </p>
                        <p>
                            Срок обработки персональных данных является неограниченным, при этом Пользователь может в
                            любой
                            момент отозвать свое согласие на обработку персональных данных, направив Оператору
                            уведомление
                            посредством электронной почты на электронный адрес Оператора https://startstage.ru/ с
                            пометкой
                            «Отзыв согласия на обработку персональных данных».
                        </p>
                        <p>
                            Оператор гарантирует соблюдение следующих прав субъекта персональных данных: право на
                            получение
                            сведений о том, какие персональные данные субъекта персональных данных хранятся у Оператора;
                            право на удаление, уточнение или исправление хранящихся у Оператора персональных данных;
                            иные
                            права, установленные действующим законодательством Российской Федерации.
                        </p>
                        <p>
                            Оператор вправе в любое время в одностороннем порядке вносить изменения в условия настоящего
                            Соглашения и сопутствующие документы без уведомления Пользователя. Изменения вступают в силу
                            с
                            момента опубликования новой версии документа на Сайте.
                        </p>
                        <p>
                            Актуальная действующая редакция Соглашения размещена по URL адресу:
                            https://startstage.ru/agreement/.
                        </p>

                        <p>
                            Оператор
                            ООО «ГВУ»
                            Адрес электронной почты: info@startstage.ru
                            ОГРН 1185958021545, ИНН 5902049236
                            Адрес места нахождения: 614013, Пермский край, город Пермь, улица Академика Королева, дом
                            21,
                            офис 213
                        </p>


                        <h4>Политика в отношении обработки персональных данных</h4>

                        <ol>
                            <li>
                                Общие положения
                                Настоящая политика обработки персональных данных составлена в соответствии с
                                требованиями
                                Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» и определяет порядок
                                обработки персональных данных и меры по обеспечению безопасности персональных данных,
                                предпринимаемые ООО «ГВУ» (далее – Оператор).
                                <br/>
                                1.1. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности
                                соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в
                                том
                                числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
                                <br/>
                                1.2. Настоящая политика Оператора в отношении обработки персональных данных (далее –
                                Политика)
                                применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта
                                https://startstage.ru/.
                            </li>

                            <li>
                                Основные понятия, используемые в Политике
                                <br/>
                                2.1. Автоматизированная обработка персональных данных – обработка персональных данных с
                                помощью
                                средств вычислительной техники;
                                <br/>
                                2.2. Веб-сайт – совокупность графических и информационных материалов, а также программ
                                для ЭВМ и
                                баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу
                                https://startstage.ru/;
                                <br/>
                                2.3. Информационная система персональных данных — совокупность содержащихся в базах
                                данных
                                персональных данных и обеспечивающих их обработку информационных технологий и
                                технических
                                средств;
                                <br/>
                                2.4. Обработка персональных данных – любое действие (операция) или совокупность действий
                                (операций), совершаемых с использованием средств автоматизации или без использования
                                таких
                                средств с персональными данными, включая сбор, запись, систематизацию, накопление,
                                хранение,
                                уточнение (обновление, изменение), извлечение, использование, передачу (распространение,
                                предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных
                                данных;
                                <br/>
                                2.5. Оператор – общество с ограниченной ответственностью «Горные вентиляционные
                                устройства» (ООО
                                «ГВУ»);
                                <br/>
                                2.6. Персональные данные – любая информация, относящаяся прямо или косвенно к
                                определенному или
                                определяемому Пользователю веб-сайта https://startstage.ru/;
                                <br/>
                                2.7. Пользователь – любой посетитель веб-сайта https://startstage.ru/;
                                <br/>
                                2.8. Предоставление персональных данных – действия, направленные на раскрытие
                                персональных
                                данных определенному лицу или определенному кругу лиц;
                                <br/>
                                2.9. Распространение персональных данных – любые действия, направленные на раскрытие
                                персональных данных неопределенному кругу лиц (передача персональных данных) или на
                                ознакомление
                                с персональными данными неограниченного круга лиц, в том числе обнародование
                                персональных данных
                                в средствах массовой информации, размещение в информационно-телекоммуникационных сетях
                                или
                                предоставление доступа к персональным данным каким-либо иным способом;
                                <br/>
                                2.10. Трансграничная передача персональных данных – передача персональных данных на
                                территорию
                                иностранного государства органу власти иностранного государства, иностранному
                                физическому или
                                иностранному юридическому лицу;
                                <br/>
                                2.11. Уничтожение персональных данных – любые действия, в результате которых
                                персональные данные
                                уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания
                                персональных
                                данных в информационной системе персональных данных и (или) уничтожаются материальные
                                носители
                                персональных данных.
                                <br/>
                                2.12. Файлы cookie – текстовые файлы небольшого размера, которые сохраняются на вашем
                                устройстве
                                (персональном компьютере, ноутбуке, планшете, мобильном телефоне и т.п.), когда вы
                                посещаете
                                сайты в сети «Интернет».
                                Кроме того, при посещении сайта https://startstage.ru/ в сети «Интернет» происходит
                                автоматический сбор иных данных, в том числе: технических характеристик устройства,
                                IP-адреса,
                                информации об используемом браузере и языке, даты и времени доступа к сайту, адресов
                                запрашиваемых страниц сайта и иной подобной информации.
                            </li>
                            <li>
                                Оператор может обрабатывать следующие персональные данные Пользователя
                                <br/>
                                3.1. Оператор может обрабатывать следующие персональные данные Пользователя: фамилия,
                                имя,
                                отчество; дата рождения; место рождения, адрес; семейное положение; социальное
                                положение;
                                имущественное положение; образование; профессия; доходы; ИНН, СНИЛС, контактная
                                информация
                                (телефон, адрес электронной почты), иные сведения, предусмотренные типовыми формами и
                                установленным порядком обработки.
                                <br/>
                                3.2. Вышеперечисленные данные далее по тексту Политики объединены общим понятием
                                Персональные
                                данные.
                            </li>
                            <li>
                                Цели обработки персональных данных
                                <br/>
                                4.1. Цель обработки персональных данных Пользователя — предоставление доступа
                                Пользователю к
                                сервисам, информации и/или материалам, услугам, содержащимся на веб-сайте
                                https://startstage.ru/
                                и в рассылке.
                                <br/>
                                4.2. Также Оператор имеет право направлять Пользователю уведомления о новых услугах,
                                специальных
                                предложениях и различных событиях. Пользователь всегда может отказаться от получения
                                информационных сообщений, направив Оператору письмо на адрес электронной почты
                                https://startstage.ru/ с пометкой «Отказ от уведомлений о новых продуктах и услугах и
                                специальных предложениях».
                                <br/>
                                4.3. Оператор имеет право отказать в предоставлении услуг Пользователю без обоснования
                                причины
                                такого отказа.
                            </li>
                            <li>
                                Порядок сбора, хранения, передачи и других видов обработки персональных данных
                                <br/>
                                5.1. Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается
                                путем
                                реализации правовых, организационных и технических мер, необходимых для выполнения в
                                полном
                                объеме требований действующего законодательства в области защиты персональных данных.
                                <br/>
                                5.2. Оператор обрабатывает персональные данные Пользователя только в случае их
                                заполнения и/или
                                отправки Пользователем самостоятельно через специальные формы, расположенные на сайте
                                https://startstage.ru/ или электронную почту: info@startstage.ru/. Заполняя
                                соответствующие
                                формы и/или отправляя свои персональные данные Оператору, Пользователь выражает свое
                                согласие с
                                данной Политикой
                                <br/>
                                5.3. Оператор обеспечивает сохранность персональных данных и принимает все возможные
                                меры,
                                исключающие доступ к персональным данным неуполномоченных лиц.
                                <br/>
                                5.4. Персональные данные Пользователя никогда, ни при каких условиях не будут переданы
                                третьим
                                лицам, за исключением случаев, связанных с исполнением действующего законодательства.
                                <br/>
                                5.5. В случае выявления неточностей в персональных данных, Пользователь может
                                актуализировать их
                                самостоятельно, путем направления Оператору уведомление на адрес электронной почты
                                Оператора
                                https://startstage.ru/ с пометкой «Актуализация персональных данных».
                                <br/>
                                5.6. Срок обработки персональных данных является неограниченным. Пользователь может в
                                любой
                                момент отозвать свое согласие на обработку персональных данных, направив Оператору
                                уведомление
                                посредством электронной почты на электронный адрес Оператора https://startstage.ru/ с
                                пометкой
                                «Отзыв согласия на обработку персональных данных».
                            </li>
                            <li>
                                Заключительные положения
                                <br/>
                                6.1. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся
                                обработки его персональных данных, обратившись к Оператору с помощью электронной почты
                                https://startstage.ru/.
                                <br/>
                                6.2. Настоящая Политика подлежит изменению, дополнению в случае появления новых
                                законодательных
                                актов и специальных нормативных документов по обработке и защите персональных данных,
                                или по
                                инициативе Компании. При внесении изменений в актуальной редакции указывается дата
                                последнего
                                обновления. Новая редакция Политики вступает в силу с момента ее размещения, если иное
                                не
                                предусмотрено новой редакцией Политики.
                                <br/>
                                6.3. Актуальная версия Политики в свободном доступе расположена в сети Интернет по
                                адресу:
                                https://startstage.ru/agreement/.
                                <br/>
                                6.4. Последняя редакция: «01» декабря 2021 года.
                            </li>
                        </ol>

                        <p>
                            Оператор
                            ООО «ГВУ»
                            Адрес электронной почты: info@startstage.ru
                            ОГРН 1185958021545, ИНН 5902049236
                            Адрес места нахождения: 614013, Пермский край, город Пермь, улица Академика Королева, дом
                            21,
                            офис 213
                        </p>

                    </article>

                </section>

            </div>
        </div>
    }
};
