import {BaseApi} from './BaseApi'
import {Institute, InstituteForm, Institutes} from '../types/institutes'
import {Search} from '../types/common'
import {Support, SupportForm} from '../types/supports'
import {New, News, NewsCategory} from '../types/news'


class NewsApi extends BaseApi {

    public async getNews(page: number, search?: Search): Promise<News> {
        return this.postRequest(`/getnews/${page}`, {search} || {}).then(
            response => ({
                list: response.data,
                count: response.count || 0,
            }),
        )
    }

    public async getNewsDetailed(id: number|string): Promise<New> {
        return this.getRequest(`/getonenews/${id}`).then(
            response => response.data,
        )
    }

    public async getNewsCategories(): Promise<NewsCategory[]> {
        return this.getRequest(`/getnewscategories`).then(
            response => response.data,
        )
    }

}

export default new NewsApi()
