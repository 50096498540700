import React from 'react'
import BasePage from './BasePage'
import HeadMetaComponent from '../components/HeadMetaComponent'
import StaticApi from '../api/StaticApi'
import {Static} from '../types/statics'
import routes from '../routes/routes'

type Props = {
    history: any
    match: any
}
type State = {
    url: string
    statPage?: Static
}


export default class StaticPage extends BasePage<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            url: this.getUrl(),
        }
    }

    protected getHeaderTabKey(): string {
        return 'statics'
    }

    protected getPageTitle(): string {
        return 'Центр трансфера технологий'
    }

    private getUrl(): string {
        return this.props.match.params.url
    }

    componentDidMount(): void {
        this.load()
    }

    protected load() {
        StaticApi.getOne(this.state.url).then(statPage => {
            if (statPage) this.setState({statPage})
            else document.location.href = routes.notFoundPage.path
        })
    }

    renderBody() {
        const {statPage} = this.state

        return <div className="row">
            <HeadMetaComponent
                title={statPage?.title_meta || this.getPageTitle()}
                description={statPage?.description_meta}
                keywords={statPage?.keywords_meta}
            />
            <div className="col-xs-12 col-sm-12 col-md-12 col-xxl-12">
                <section className="mt-4 kartochka knews">
                    <article className="mb-4">
                        <div className="article">
                            <div className="kn">
                                <h1 className="mt-5 mb-5">{statPage?.title}</h1>
                                <div className="clr"/>
                                <div dangerouslySetInnerHTML={{__html: statPage?.content || ''}}/>
                            </div>
                        </div>
                    </article>
                </section>
            </div>
        </div>
    }
};
