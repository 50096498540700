import {
    Support,
    SupportDirection,
    SupportForm,
    SupportMember,
    SupportReason,
    Supports,
    SupportSave,
} from '../types/supports'
import {BaseApi, BaseResponse} from './BaseApi'
import {Search} from '../types/common'


class SupportApi extends BaseApi {

    public async getSupports(page: number, search?: Search): Promise<Supports> {
        return this.postRequest(`/getsupports/${page}`, {search} || {}).then(
            response => ({
                list: response.data,
                count: response.count || 0,
                min_amount: response.min_amount || 0,
                max_amount: response.max_amount || 0,
            }),
        )
    }

    public async getSupport(id: number): Promise<Support> {
        return this.getRequest(`/getsupport/${id}`).then(
            response => response.data,
        )
    }

    public async getSupportStatuses(): Promise<string[]> {
        return this.getRequest('/getsupportstatuses').then(
            response => response.data,
        )
    }

    public async getSupportForms(): Promise<SupportForm[]> {
        return this.getRequest('/getsupportforms').then(
            response => response.data,
        )
    }

    public async getSupportDirections(): Promise<SupportDirection[]> {
        return this.getRequest('/getsupportdirections').then(
            response => response.data,
        )
    }

    public async getSupportMembers(): Promise<SupportMember[]> {
        return this.getRequest('/getsupportmembers').then(
            response => response.data,
        )
    }

    public async getSupportReasons(): Promise<SupportReason[]> {
        return this.getRequest('/getsupportreasons').then(
            response => response.data,
        )
    }

    public async saveSupport(support: SupportSave): Promise<BaseResponse> {
        return this.postRequest('/sendsupport', support)
    }

    public async deleteLogo(id: number): Promise<BaseResponse> {
        return this.postRequest('/deletesupportlogo', {id_supports: id})
    }

    public async deleteSupports(ids: (number | string)[]): Promise<BaseResponse> {
        return this.postRequest('/deletesupports', {
            'list': ids.map(id => ({'id_supports': id})),
        })
    }

    public async subscribe(url: string, email: string, name: string): Promise<BaseResponse> {
        return this.postRequest('/sendsubscribesearch', {
            url, email, fio: name,
        })
    }

}

export default new SupportApi()
