import {BaseApi, BaseResponse} from './BaseApi'
import {Search} from '../types/common'
import {Static, Statics, StaticSave, StaticStatus} from '../types/statics'


class StaticApi extends BaseApi {

    public async getList(page: number, search?: Search): Promise<Statics> {
        return this.postRequest(`/getstaticpages/${page}`, {search} || {}).then(
            response => ({
                list: response.data,
                count: response.count || 0,
            }),
        )
    }

    public async getOne(id: number|string): Promise<Static> {
        return this.getRequest(`/getstaticpage/${id}`).then(
            response => response.data,
        )
    }

    public async getStatuses(): Promise<StaticStatus[]> {
        return this.getRequest(`/getstaticpagestatuses`).then(
            response => response.data,
        )
    }

}

export default new StaticApi()
