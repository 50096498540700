export const errors = {
    incorrect_email: 'неверный email',
    no_email: 'email не задан',
    incorrect_url: 'неверный url',
    no_url: 'url не задан',
    no_data: 'пустой запрос/неверный формат данных',
    error_db: 'ошибка БД',
    no_fio: 'нет фио',
    incorrect_fio: 'некорректное имя',
    no_permission: 'нет прав',
    no_auth: 'не авторизован',
    no_user: 'нет пользователя или неверный пароль',
    no_login: 'не указан логин',

    no_institute_name: 'не задано имя института',
    incorrect_id_institutes: 'заданный ID института некорректен',
    incorrect_id_institution_statuses: 'заданный ID статуса некорректный',
    invalid_file_format: 'неверный формат файла лого',
    incorrect_site: 'заданный сайт некорректен',
    incorrect_id_regions: 'заданные ID региона некорректны/один из заданных ID региона некорректен',
    incorrect_institution_forms: 'заданные ID форм некорректны/один из заданных ID форм некорректен',
    file_error: 'ошибка записи файла',
    duplicate_name: 'такое имя уже есть',

    incorrect_id_support_statuses: 'заданный ID статуса некорректный',
    incorrect_id_supports: 'заданный ID поддержки некорректен',
    no_support_name: 'не задано имя поддержки (по-умолчанию)',
    incorrect_id_support_forms: 'заданные ID форм некорректны/один из заданных ID форм некорректен',
    incorrect_id_support_members: 'заданные ID участников некорректны/один из заданных ID участников некорректен',
    incorrect_id_support_directions: 'заданные ID направления некорректны/один из заданных ID направления некорректен',

    short_name: 'слишком короткое имя (менее 3х символов)',
    no_name: 'нет имени',
    short_message: 'слишком короткое сообщение (менее 4х символов)',
    no_message: 'нет сообщения',
}