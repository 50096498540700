import React from 'react'
import BasePage from './BasePage'
import routes from '../routes/routes'
import {Link} from 'react-router-dom'

type Props = {}
type State = {}


export default class NotFoundPage extends BasePage<Props, State> {
    protected getHeaderTabKey(): string {
        return 'not_found'
    }

    protected getPageTitle(): string {
        return 'Не найдено - Центр трансфера технологий'
    }

    renderBody() {
        return <div className="row">
            <h1 className="h1-st mb-4 mt-4">Страница не найдена!</h1>

            <div className="col-5 col-sm-8 col-xl-4 col-xxl-5 col-lg-8 col-md-8 homebtn">
                <Link to={routes.mainPage.path} href="/"><i className="fa fa-home" aria-hidden="true"/>Главная</Link>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
    }
};
