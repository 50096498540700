import React, {ReactElement} from 'react'
import HeaderMenu from '../components/HeaderMenu'
import HeadMetaComponent from '../components/HeadMetaComponent'
import Footer from '../components/Footer'
import routes from '../routes/routes'
import Authenticator from '../components/Authenticator'


export default abstract class BasePage<Props, State> extends React.Component<Props, State> {

    protected abstract renderBody(): ReactElement;

    protected renderOffBody(): ReactElement {
        return <div/>
    }

    protected getHeaderTabKey(): string {
        return ''
    }

    protected getPageTitle(): string {
        return 'Центр трансфера технологий'
    }

    renderMenu() {
        return <HeaderMenu
            tabKey={this.getHeaderTabKey()}
            onSearch={
                (event) => {
                    event.preventDefault()
                    document.location.href = `${routes.supportsPage.path}?search=${event.target.parentNode.parentNode.children[0].value}`
                }
            }
        />
    }

    render() {
        return <div>
            <HeadMetaComponent title={this.getPageTitle()}/>
            <Authenticator/>
            <div className="container-fluid">
                <div className="container">
                    {this.renderMenu()}
                    {this.renderBody()}
                </div>
            </div>
            <Footer/>
            {this.renderOffBody()}
        </div>
    }
}

