import React from 'react'
import vkLogo from '../resources/vk.svg'
import vkLogoWhite from '../resources/vk-white.svg'

export const EMAIL = 'info@permctt.ru'
export const TELEGRAM = 'https://t.me/permctt'
export const VK = 'https://vk.com/politehperm'
export const ADDRESS = '614000, г. Пермь, Комсомольский пр., 29, ауд. 323'
export const PHONE = '+7 (342) 2-198-029'
export const PHONE_LINK = 'tel:+73422198029'
export const SITE = 'permctt.ru'

export const NETWORKS = [
    <a href={VK} target="_blank" rel="noopener noreferrer" className='vk-logo-home'>
        <img src={vkLogo}/>
    </a>,
    <a href={TELEGRAM} target="_blank" rel="noopener noreferrer"><i className="fa fa-telegram" aria-hidden="true"/></a>,
]

export const NETWORKS_FIRST_MARGIN = [
    <a href={VK} target="_blank" rel="noopener noreferrer" className='vk-logo-footer'>
        <img src={vkLogoWhite}/>
    </a>,
    <a href={TELEGRAM} target="_blank" rel="noopener noreferrer"><i className="fa fa-telegram" aria-hidden="true"/></a>,
]

export const NETWORKS_CONTACTS = [
    <a href={VK} target="_blank" rel="noopener noreferrer" className='vk-logo-contacts'>
        <img src={vkLogo}/>
    </a>,
    <a href={TELEGRAM} target="_blank" rel="noopener noreferrer"><i className="fa fa-telegram" aria-hidden="true"/></a>,
]

export const ADDRESS_WITH_UNIVERSITY_NAME = <p className="footadres">
    614000, г. Пермь, Комсомольский пр., 29, ауд. 323
    <br/>Пермский национальный исследовательский
    <br/>политехнический университет
</p>
