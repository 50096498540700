import React, {ReactElement} from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'

export type TabsProps = {
    tabKey?: string
    search?: string
    onSearchInput?: any
    onSearch?: any
}
type State = {}


export class TabItem {
    public readonly key: string
    public readonly title: any
    public readonly link: string
    public readonly disabled: boolean

    constructor(key: string, title: any, link: string, disabled: boolean) {
        this.key = key
        this.title = title
        this.link = link
        this.disabled = disabled
    }
}


export abstract class TabsBase<Props extends TabsProps = TabsProps> extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {}
    }

    protected getTabKey(): string | undefined {
        return this.props.tabKey
    }

    protected abstract getTabItems(): TabItem[]

    protected abstract renderTab(item: TabItem): ReactElement

    protected renderContentLeft() {
        return <div/>
    }

    abstract render(): ReactElement
}

const TabLink = styled(Link)<{ selected: boolean }>`
  font-weight: ${props => props.selected ? 700 : 300};
  color: black;
  text-decoration: none;
  font-size: 1.7em;
  margin: 10px;
`
