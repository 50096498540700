import {ADMIN_SITE} from '../constants/hosts'

export default {
    mainPage: {
        path: '/'
    },
    supportsPage: {
        path: '/supports'
    },
    supportPage: {
        path: '/support/:id'
    },
    institutesPage: {
        path: '/institutes'
    },
    institutePage: {
        path: '/institute/:id'
    },
    aboutPage: {
        path: '/'
    },
    newsPage: {
        path: '/news/tag/:tag'
    },
    newsDetailedPage: {
        path: '/news/:id'
    },
    newsPageBase: {
        path: '/news'
    },
    contactsPage: {
        path: '/contacts'
    },
    rulesPage: {
        path: '/rules'
    },
    staticPage: {
        path: '/:url'
    },
    notFoundPage: {
        path: '/404'
    },

    adminMainPage: {
        path: ADMIN_SITE
    },
}