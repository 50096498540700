import React from 'react'
import BasePage from './BasePage'
import SupportApi from '../api/SupportApi'
import {SupportDirection, SupportForm, SupportMember, SupportReason, Supports} from '../types/supports'
import {Link} from 'react-router-dom'
import routes from '../routes/routes'
import {Range} from 'rc-slider'
import ReactPaginate from 'react-paginate'
import 'rc-slider/assets/index.css'
import {toast} from 'react-toastify'
import SubscribeModal from '../components/SubscribeModal'
import HeaderMenu from '../components/HeaderMenu'
import arrowIcon from '../resources/arrow.png'

type Props = {
    history: any
}
type State = {
    supportMeasures?: Supports
    page: number
    pageSize: number
    sort: string

    supportForms?: SupportForm[]
    supportFormsMapping?: object
    supportDirections?: SupportDirection[]
    supportDirectionsMapping?: object
    supportMembers?: SupportMember[]
    supportMembersMapping?: object
    supportReasons?: SupportReason[]
    supportReasonsMapping?: object

    filters: {
        forms: number[]
        directions: number[]
        members: number[]
        min_amount?: number
        max_amount?: number
        search?: string
    }

    subscribeModalOpen: boolean

    arrowPositionOpen: {
        1: boolean
        2: boolean
        3: boolean
    }
}

const DEFAULT_SORT_DIRECTION = 'date_creation-inverse'


export default class SupportsPage extends BasePage<Props, State> {
    constructor(props: Props) {
        super(props)
        const params = new URLSearchParams(this.props.history.location.search)
        this.state = {
            page: parseInt(params.get('page') || '1'),
            pageSize: parseInt(params.get('page_size') || '5'),
            sort: params.get('sort') || DEFAULT_SORT_DIRECTION,

            filters: {
                // @ts-ignore
                forms: params.get('forms') ? params.get('forms').split(',').map(s => parseInt(s)) : [],
                // @ts-ignore
                directions: params.get('directions') ? params.get('directions').split(',').map(s => parseInt(s)) : [],
                // @ts-ignore
                members: params.get('members') ? params.get('members').split(',').map(s => parseInt(s)) : [],
                // @ts-ignore
                min_amount: params.get('min_amount') ? parseInt(params.get('min_amount')) : undefined,
                // @ts-ignore
                max_amount: params.get('max_amount') ? parseInt(params.get('max_amount')) : undefined,
                search: params.get('search') || undefined,
            },

            subscribeModalOpen: false,

            arrowPositionOpen: {
                1: true,
                2: false,
                3: false,
            },
        }
    }

    protected getHeaderTabKey(): string {
        return 'supports'
    }

    protected getPageTitle(): string {
        return 'Меры поддержки - Центр трансфера технологий'
    }

    componentDidMount(): void {
        this.load()
        this.loadFilters()
    }

    setQueryParams = () => {
        // @ts-ignore
        const history = this.props.history

        let searchPath = `?page=${this.state.page}&page_size=${this.state.pageSize}` +
            `&sort=${this.state.sort}` +
            `&forms=${this.state.filters.forms.join(',')}` +
            `&directions=${this.state.filters.directions.join(',')}` +
            `&members=${this.state.filters.members.join(',')}`
        if (this.state.filters.min_amount !== undefined)
            searchPath += `&min_amount=${this.state.filters.min_amount}`
        if (this.state.filters.max_amount !== undefined)
            searchPath += `&max_amount=${this.state.filters.max_amount}`
        if (this.state.filters.search !== undefined)
            searchPath += `&search=${this.state.filters.search}`

        history.push({
            pathname: history.location.pathname,
            search: searchPath,
        })
    }

    handlePageChange = (event: any) => {
        this.setState({page: event.selected + 1}, () => {
            this.load()
            this.setQueryParams()
        })
    }

    protected load() {
        SupportApi.getSupports(
            this.state.page,
            {
                maxelem: this.state.pageSize,
                sort: this.state.sort,
                support_forms: this.state.filters.forms.join(','),
                support_members: this.state.filters.members.join(','),
                support_directions: this.state.filters.directions.join(','),
                start_amount: this.state.filters.min_amount,
                end_amount: this.state.filters.max_amount,
                support_name: this.state.filters.search,
            },
        ).then(
            supportMeasures => {
                this.setState({
                    supportMeasures,
                })
            },
        )
    }

    protected loadFilters() {
        SupportApi.getSupportForms().then(
            dataList => {
                const supportMapping = {}
                dataList.forEach(x => {
                    // @ts-ignore
                    supportMapping[x.support_form_name] = x.id_support_forms
                })
                this.setState({supportFormsMapping: supportMapping, supportForms: dataList})
            },
        )
        SupportApi.getSupportDirections().then(
            dataList => {
                const supportMapping = {}
                dataList.forEach(x => {
                    // @ts-ignore
                    supportMapping[x.support_direction_name] = x.id_support_directions
                })
                this.setState({supportDirectionsMapping: supportMapping, supportDirections: dataList})
            },
        )
        SupportApi.getSupportMembers().then(
            dataList => {
                const supportMapping = {}
                dataList.forEach(x => {
                    // @ts-ignore
                    supportMapping[x.support_members_name] = x.id_support_members
                })
                this.setState({supportMembersMapping: supportMapping, supportMembers: dataList})
            },
        )
        SupportApi.getSupportReasons().then(
            dataList => {
                const supportMapping = {}
                dataList.forEach(x => {
                    // @ts-ignore
                    supportMapping[x.support_reason_name] = x.id_support_reasons
                })
                this.setState({supportReasonsMapping: supportMapping, supportReasons: dataList})
            },
        )
    }

    handleChangeSort = (event: any) => {
        this.setState({sort: event.target.value}, () => {
            this.load()
            this.setQueryParams()
        })
    }

    handleFilterApply = (type: string, id: number) => (event: any) => {
        const checked = event.target.checked
        const arr = this.state.filters[type]

        if (checked) {
            arr.push(id)
        } else {
            const index = arr.indexOf(id)
            if (index > -1) {
                arr.splice(index, 1)
            }
        }

        this.setState({
            filters: {
                ...this.state.filters,
                [type]: arr,
            },
        }, () => {
            this.load()
            this.setQueryParams()
        })
    }

    handleFilterAmountApply = (type: string) => (event: any) => {
        const value = event.target.value
        console.log(type, value)
        this.setState({
            filters: {
                ...this.state.filters,
                [type]: value,
            },
        }, () => {
            this.load()
            this.setQueryParams()
        })
    }

    onSearchInput = (event: any) => {
        this.setState({
            filters: {
                ...this.state.filters,
                search: event.target.value,
            },
        })
    }

    onSearch = (event: any) => {
        event.preventDefault()
        this.load()
        this.setQueryParams()
    }

    handleFilterAmountSliderApply = (doLoad: boolean) => (amount: number[]) => {
        console.log(amount)
        this.setState({
            filters: {
                ...this.state.filters,
                min_amount: amount[0],
                max_amount: amount[1],
            },
        }, () => {
            if (doLoad) {
                this.load()
                this.setQueryParams()
            }
        })
    }

    onModalOpen = (event: any) => {
        event.preventDefault()
        this.setState({subscribeModalOpen: true})
    }

    onModalClose = (event: any) => {
        event.preventDefault()
        this.setState({subscribeModalOpen: false})
    }

    subscribe = (email: string, name: string) => {
        const history = this.props.history
        SupportApi.subscribe(`https://local.host${history.location.search}`, email, name).then(() => {
            toast.success('Вы подписаны')
            this.setState({subscribeModalOpen: false})
        }).catch(err => {
            console.error(err)
        })
    }

    renderMenu() {
        return <HeaderMenu
            tabKey={this.getHeaderTabKey()}
            search={this.state.filters.search}
            onSearchInput={this.onSearchInput}
            onSearch={this.onSearch}
        />
    }

    renderBody() {
        const {supportMeasures, page, pageSize, filters, arrowPositionOpen} = this.state

        let pageCount = 0
        if (supportMeasures) pageCount = Math.ceil(supportMeasures.count / pageSize)

        return <div className="row">
            <h1 className="h1-st mb-4 mt-4">Меры поддержки</h1>

            <div className="col-xs-12 col-xl-3 col-xxl-2 col-sm-12 col-lg-3 col-md-4">
                <aside>
                    <div className="filtr">

                        <form className="mb-5">

                            {(this.state.supportDirections && this.state.supportDirections.length > 0) && <h5>
                                <a data-bs-toggle="collapse" href="#collapse1" role="button" aria-expanded="true"
                                   aria-controls="collapse1"
                                   onClick={() => this.setState({
                                       arrowPositionOpen: {
                                           ...arrowPositionOpen,
                                           1: !arrowPositionOpen[1],
                                       },
                                   })}
                                >
                                    Направление
                                    <i aria-hidden="true"
                                       className={arrowPositionOpen[1] ? 'fa fa-caret-up' : 'fa fa-caret-down'}/>
                                </a>
                            </h5>}
                            <div id="collapse1" className="collapse show">
                            {
                                this.state.supportDirections?.map(x => <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={x.support_direction_name}
                                        id={`dir-${x.id_support_directions}`}
                                        key={`dir-${x.id_support_directions}`}
                                        onChange={this.handleFilterApply('directions', x.id_support_directions)}
                                        checked={filters.directions.includes(x.id_support_directions)}
                                    />
                                    <label className="form-check-label"
                                           htmlFor={`dir-${x.id_support_directions}`}>{x.support_direction_name}</label>
                                </div>)
                            }
                            </div>

                            {(this.state.supportMembers && this.state.supportMembers.length > 0) && <h5>
                                <a data-bs-toggle="collapse" href="#collapse2" role="button" aria-expanded="true"
                                   aria-controls="collapse2"
                                   onClick={() => this.setState({
                                       arrowPositionOpen: {
                                           ...arrowPositionOpen,
                                           2: !arrowPositionOpen[2],
                                       },
                                   })}
                                >
                                    Участники
                                    <i aria-hidden="true"
                                       className={arrowPositionOpen[2] ? 'fa fa-caret-up' : 'fa fa-caret-down'}/>
                                </a>
                            </h5>}
                            <div id="collapse2" className="collapse">
                            {
                                this.state.supportMembers?.map(x => <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={x.support_members_name}
                                        id={`mem-${x.id_support_members}`}
                                        key={`mem-${x.id_support_members}`}
                                        onChange={this.handleFilterApply('members', x.id_support_members)}
                                        checked={filters.members.includes(x.id_support_members)}
                                    />
                                    <label className="form-check-label"
                                           htmlFor={`mem-${x.id_support_members}`}>{x.support_members_name}</label>
                                </div>)
                            }
                            </div>

                            {(this.state.supportForms && this.state.supportForms.length > 0) && <h5>
                                <a data-bs-toggle="collapse" href="#collapse3" role="button" aria-expanded="true"
                                   aria-controls="collapse3"
                                   onClick={() => this.setState({
                                       arrowPositionOpen: {
                                           ...arrowPositionOpen,
                                           3: !arrowPositionOpen[3],
                                       },
                                   })}
                                >
                                    Формы поддержки
                                    <i aria-hidden="true"
                                       className={arrowPositionOpen[3] ? 'fa fa-caret-up' : 'fa fa-caret-down'}/>
                                </a>
                            </h5>}
                            <div id="collapse3" className="collapse">
                            {
                                this.state.supportForms?.map(x => <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={x.support_form_name}
                                        id={`form-${x.id_support_forms}`}
                                        key={`form-${x.id_support_forms}`}
                                        onChange={this.handleFilterApply('forms', x.id_support_forms)}
                                        checked={filters.forms.includes(x.id_support_forms)}
                                    />
                                    <label className="form-check-label"
                                           htmlFor={`form-${x.id_support_forms}`}>{x.support_form_name}</label>
                                </div>)
                            }
                            </div>

                            <h5 className="mt-4">Сумма</h5>
                            <input
                                type="number" className="filtr-ot form-control"
                                value={filters.min_amount || supportMeasures?.min_amount}
                                min={supportMeasures?.min_amount || 0}
                                onChange={this.handleFilterAmountApply('min_amount')}
                            /> - <input
                            type="number" className="filtr-do form-control"
                            value={filters.max_amount || supportMeasures?.max_amount}
                            min={supportMeasures?.min_amount || 0}
                            max={supportMeasures?.max_amount}
                            onChange={this.handleFilterAmountApply('max_amount')}
                        />
                            <Range
                                className="form-range mt-4"
                                min={supportMeasures?.min_amount}
                                max={supportMeasures?.max_amount}
                                value={[filters.min_amount || supportMeasures?.min_amount || 0, filters.max_amount || supportMeasures?.max_amount || 0]}
                                onChange={this.handleFilterAmountSliderApply(false)}
                                onAfterChange={this.handleFilterAmountSliderApply(true)}
                            />

                            {/*it was <a> instead of <button>*/}
                            <button className="btn btn-primary mt-3" onClick={this.onModalOpen}>
                                Подписка на фильтр
                            </button>
                        </form>

                    </div>
                </aside>
            </div>

            <div
                className="col-xs-12 col-sm-12 col-lg-8 offset-lg-1 col-xl-8 offset-xl-1 col-xxl-9 offset-xxl-1 col-md-8">
                <section className="mb-4">

                    <div className="sort mb-4">
                        <div className="form-group">
                            <select
                                className="form-control"
                                onChange={this.handleChangeSort}
                                defaultValue={DEFAULT_SORT_DIRECTION}
                            >
                                <option value="support_name">По названию ↑</option>
                                <option value="support_name-inverse">По названию ↓</option>
                                <option value="amount">По сумме ↑</option>
                                <option value="amount-inverse">По сумме ↓</option>
                                <option value="date_creation">По дате публикации ↑</option>
                                <option value="date_creation-inverse">По дате публикации ↓</option>
                                <option value="date_end">По дате окончания ↑</option>
                                <option value="date_end-inverse">По дате окончания ↓</option>
                            </select>
                        </div>
                    </div>

                    <div className="row">

                        {
                            supportMeasures && supportMeasures.list.map(
                                support => <article className="mb-4" key={support.id_supports}>

                                    <div className="article">
                                        <Link to={routes.supportPage.path.replace(':id', support.id_supports)}
                                              className="stretched-link">
                                            {
                                                !!support.date_end &&
                                              <div className="a-date float-start">
                                                  <i className="fa fa-clock-o" aria-hidden="true"/>
                                                  {support.date_end}
                                              </div>
                                            }

                                            <div className="float-end">
                                                {
                                                    support.support_forms && support.support_forms.map(form => <div
                                                        className="a-forma float-end">{form.support_form_name}</div>)
                                                }
                                            </div>
                                            <div className="clr"/>
                                            <h2 className="mt-4 mb-5">{support.support_name_manual || support.support_name}</h2>
                                            <div className="float-end">
                                                {
                                                    support.no_finance
                                                        ?
                                                        <div className="a-cena mb-3"><u>Нефинансовая мера поддержки</u></div>
                                                        :
                                                        <div className="a-cena mb-3">
                                                            <b>{support.amount.toLocaleString('ru-RU')}</b><u>тыс. руб.</u>
                                                        </div>
                                                }
                                                <div className="a-srok float-end">
                                                    {!!support.remained_count &&
                                                      <i className="fa fa-clock-o" aria-hidden="true"/>}
                                                    <u>
                                                        {support.remained_count} {support.remained_days} <img
                                                        className="arrow" src={arrowIcon}/>
                                                    </u>
                                                </div>
                                            </div>
                                            <div className="clr"/>
                                        </Link>
                                    </div>
                                    {
                                        support.institute_name && support.id_institutes && <div className="float-start">
                                          <div className="a-firma">
                                              <Link
                                                to={routes.institutePage.path.replace(':id', support.id_institutes.toString())}
                                                className="stretched-link"
                                              >
                                                  {support.institute_name}
                                              </Link>
                                          </div>
                                      </div>
                                    }

                                </article>,
                            )
                        }

                        <ReactPaginate
                            breakLabel="..."
                            nextLabel={<i className="fa fa-angle-right" aria-hidden="true"/>}
                            onPageChange={this.handlePageChange}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            initialPage={page - 1}
                            previousLabel={<i className="fa fa-angle-left" aria-hidden="true"/>}
                            // @ts-ignore
                            renderOnZeroPageCount={null}
                            containerClassName="pagination"
                            pageClassName="page-item"
                            previousClassName="page-item"
                            nextClassName="page-item"
                            breakClassName="page-item"
                            pageLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            breakLinkClassName="page-link"
                            activeLinkClassName="page-link-active"
                        />

                    </div>

                </section>

            </div>
        </div>
    }

    renderOffBody() {
        const {subscribeModalOpen} = this.state
        return <SubscribeModal
            isOpen={subscribeModalOpen}
            onClose={this.onModalClose}
            onSubmit={this.subscribe}
        />
    }
};
