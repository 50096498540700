import React from 'react'
import BasePage from './BasePage'
import InstituteApi from '../api/InstituteApi'
import {InstituteForm, Institutes} from '../types/institutes'
import {HOST} from '../constants/hosts'
import ReactPaginate from 'react-paginate'
import defaultImage from '../resources/default_image.png'
import {SupportForm, SupportMember} from '../types/supports'
import SupportApi from '../api/SupportApi'
import { Link } from 'react-router-dom'
import routes from '../routes/routes'

type Props = {
    history: any
    match: any
}
type State = {
    institutes?: Institutes
    page: number
    pageSize: number

    forms?: InstituteForm[]
    formsMapping?: object
    supportForms?: SupportForm[]
    supportFormsMapping?: object
    supportMembers?: SupportMember[]
    supportMembersMapping?: object

    filters: {
        forms: number[]
        supportForms: number[]
        members: number[]
        search?: string
    }

    arrowPositionOpen: {
        1: boolean
        2: boolean
        3: boolean
    }
}


export default class InstitutesPage extends BasePage<Props, State> {
    constructor(props: Props) {
        super(props)
        const params = new URLSearchParams(this.props.history.location.search)
        this.state = {
            page: parseInt(params.get('page') || '1'),
            pageSize: parseInt(params.get('page_size') || '8'),

            filters: {
                forms: params.get('forms') && params.get('forms')?.split(',').map(s => parseInt(s)) || [],
                supportForms: params.get('support_forms') && params.get('support_forms')?.split(',').map(s => parseInt(s)) || [],
                members: params.get('members') && params.get('members')?.split(',').map(s => parseInt(s)) || [],
                search: params.get('search') && params.get('search') || undefined,
            },

            arrowPositionOpen: {
                1: true,
                2: false,
                3: false,
            },
        }
    }

    protected getHeaderTabKey(): string {
        return 'institutes'
    }

    protected getPageTitle(): string {
        return 'Институты поддержки - Центр трансфера технологий'
    }

    componentDidMount(): void {
        this.load()
        this.loadFilters()
    }

    protected load() {
        InstituteApi.getInstitutes(this.state.page, {
            maxelem: this.state.pageSize,
            institution_forms: this.state.filters.forms.join(','),
            support_forms: this.state.filters.supportForms.join(','),
            support_members: this.state.filters.members.join(','),
            institute_name: this.state.filters.search,
        }).then(
            institutes => {
                this.setState({institutes})
            },
        )
    }

    protected loadFilters() {
        InstituteApi.getInstituteForms().then(
            dataList => {
                const mapping = {}
                dataList.forEach(x => {
                    // @ts-ignore
                    mapping[x.institution_form_name] = x.id_institution_forms
                })
                this.setState({formsMapping: mapping, forms: dataList})
            },
        )
        SupportApi.getSupportForms().then(
            dataList => {
                const supportMapping = {}
                dataList.forEach(x => {
                    // @ts-ignore
                    supportMapping[x.support_form_name] = x.id_support_forms
                })
                this.setState({supportFormsMapping: supportMapping, supportForms: dataList})
            },
        )
        SupportApi.getSupportMembers().then(
            dataList => {
                const supportMapping = {}
                dataList.forEach(x => {
                    // @ts-ignore
                    supportMapping[x.support_members_name] = x.id_support_members
                })
                this.setState({supportMembersMapping: supportMapping, supportMembers: dataList})
            },
        )
    }

    setQueryParams = () => {
        const history = this.props.history

        let searchPath = `?page=${this.state.page}&page_size=${this.state.pageSize}` +
            `&forms=${this.state.filters.forms.join(',')}` +
            `&support_forms=${this.state.filters.supportForms.join(',')}` +
            `&members=${this.state.filters.members.join(',')}`
        if (this.state.filters.search !== undefined)
            searchPath += `&search=${this.state.filters.search}`
        history.push({
            pathname: history.location.pathname,
            search: searchPath,
        })
    }

    handlePageChange = (event: any) => {
        this.setState({page: event.selected + 1}, () => {
            this.load()
            this.setQueryParams()
        })
    }

    handleFilterApply = (type: string, id: number) => (event: any) => {
        const checked = event.target.checked
        const arr = this.state.filters[type]

        if (checked) {
            arr.push(id)
        } else {
            const index = arr.indexOf(id)
            if (index > -1) {
                arr.splice(index, 1)
            }
        }

        this.setState({
            filters: {
                ...this.state.filters,
                [type]: arr,
            },
        }, () => {
            this.load()
            this.setQueryParams()
        })
    }

    onSearchInput = (event: any) => {
        this.setState({
            filters: {
                ...this.state.filters,
                search: event.target.value,
            },
        })
    }

    onSearch = (event: any) => {
        event.preventDefault()
        this.load()
        this.setQueryParams()
    }

    renderBody() {
        const {institutes, page, pageSize, filters, arrowPositionOpen} = this.state

        let pageCount = 0
        if (institutes) pageCount = Math.ceil(institutes.count / pageSize)

        return <div className="row">
            <h1 className="h1-st mb-4 mt-4">Институты поддержки</h1>

            <div className="col-xs-12 col-xxl-3 col-sm-12 col-md-3">
                <aside>
                    <div className="filtr">
                        <form className="mb-5">
                            {(this.state.forms && this.state.forms.length > 0) && <h5>
                                <a data-bs-toggle="collapse" href="#collapse_form" role="button" aria-expanded="true"
                                   aria-controls="collapse_form"
                                   onClick={() => this.setState({
                                       arrowPositionOpen: {
                                           ...arrowPositionOpen,
                                           1: !arrowPositionOpen[1],
                                       },
                                   })}
                                >
                                    Форма института поддержки
                                    <i aria-hidden="true"
                                       className={arrowPositionOpen[1] ? 'fa fa-caret-up' : 'fa fa-caret-down'}/>
                                </a>
                            </h5>}
                            <div id="collapse_form" className="collapse show">
                                {
                                    this.state.forms?.map(x => <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={x.institution_form_name}
                                            id={`form-${x.id_institution_forms}`}
                                            key={`form-${x.id_institution_forms}`}
                                            onChange={this.handleFilterApply('forms', x.id_institution_forms)}
                                            checked={filters.forms.includes(x.id_institution_forms)}
                                        />
                                        <label className="form-check-label" htmlFor={`form-${x.id_institution_forms}`}>
                                            {x.institution_form_name}
                                        </label>
                                    </div>)
                                }
                            </div>

                            {(this.state.supportForms && this.state.supportForms.length > 0) && <h5 className="mt-4">
                                <a data-bs-toggle="collapse" href="#collapse_support_form" role="button"
                                   aria-expanded="false"
                                   aria-controls="collapse_support_form"
                                   onClick={() => this.setState({
                                       arrowPositionOpen: {
                                           ...arrowPositionOpen,
                                           2: !arrowPositionOpen[2],
                                       },
                                   })}
                                >
                                    Форма поддержки
                                    <i aria-hidden="true"
                                       className={arrowPositionOpen[2] ? 'fa fa-caret-up' : 'fa fa-caret-down'}/>
                                </a>
                            </h5>}
                            <div id="collapse_support_form" className="collapse">
                                {
                                    this.state.supportForms?.map(x => <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={x.support_form_name}
                                            id={`support-form-${x.id_support_forms}`}
                                            key={`support-form-${x.id_support_forms}`}
                                            onChange={this.handleFilterApply('supportForms', x.id_support_forms)}
                                            checked={filters.supportForms.includes(x.id_support_forms)}
                                        />
                                        <label className="form-check-label"
                                               htmlFor={`support-form-${x.id_support_forms}`}>{x.support_form_name}</label>
                                    </div>)
                                }
                            </div>

                            {(this.state.supportMembers && this.state.supportMembers.length > 0) && <h5 className="mt-4">
                                <a data-bs-toggle="collapse" href="#collapse_member" role="button" aria-expanded="false"
                                   aria-controls="collapse_member"
                                   onClick={() => this.setState({
                                       arrowPositionOpen: {
                                           ...arrowPositionOpen,
                                           3: !arrowPositionOpen[3],
                                       },
                                   })}
                                >
                                    Участник
                                    <i aria-hidden="true"
                                       className={arrowPositionOpen[3] ? 'fa fa-caret-up' : 'fa fa-caret-down'}/>
                                </a>
                            </h5>}
                            <div id="collapse_member" className="collapse">
                                {
                                    this.state.supportMembers?.map(x => <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={x.support_members_name}
                                            id={`mem-${x.id_support_members}`}
                                            key={`mem-${x.id_support_members}`}
                                            onChange={this.handleFilterApply('members', x.id_support_members)}
                                            checked={filters.members.includes(x.id_support_members)}
                                        />
                                        <label className="form-check-label"
                                               htmlFor={`mem-${x.id_support_members}`}>{x.support_members_name}</label>
                                    </div>)
                                }
                            </div>

                        </form>
                    </div>
                </aside>
            </div>


            <div className="col-xs-12 col-sm-12 col-md-9 col-xxl-9">
                <section className="mb-4 inst">
                    <div className="sort mb-4">
                        <form className="search" onSubmit={this.onSearch}>
                            <input
                                type="text"
                                placeholder="Поиск институтов поддержки"
                                value={this.state.filters.search}
                                onChange={this.onSearchInput}
                            />
                            <button type="submit"><i className="fa fa-search" aria-hidden="true" onClick={this.onSearch}/></button>
                        </form>
                    </div>

                    <div className="row">
                        {
                            institutes && institutes.list.map(
                                institute => <article className="col-6 mb-5">
                                    <div className="article">
                                        <Link to={routes.institutePage.path.replace(':id', institute.id_institutes.toString())} className="stretched-link">
                                            <div className="inst-img d-flex">
                                                <img className="d-block m-auto mh-100 mw-100" src={institute.logo ? `${HOST}/${institute.logo}` : defaultImage}/>
                                            </div>
                                            <h2 className="mt-4 mb-4">{institute.institute_name}</h2>
                                            {
                                                institute.institution_forms &&
                                                institute.institution_forms.map(
                                                    form => <div
                                                        className="float-start a-univ">{form.institution_form_name}</div>,
                                                )
                                            }
                                            <div className="clr"/>
                                            {
                                                institute.regions &&
                                                institute.regions.map(
                                                    region => <div
                                                        className="float-start a-gorod">{region.region_name}</div>,
                                                )
                                            }
                                            {
                                                institute.count_support ?
                                                    <div className="float-end a-proj mt-2 mb-2">
                                                        <b>{institute.count_support}</b><u>количество<br/>мер поддержки</u>
                                                    </div>
                                                    : <div/>
                                            }
                                            <div className="clr"/>
                                        </Link>
                                    </div>
                                    <div className="float-end homebtn">
                                        <Link to={routes.institutePage.path.replace(':id', institute.id_institutes.toString())}>Подробнее</Link>
                                    </div>
                                </article>,
                            )
                        }


                        <div className="clr"/>

                        <ReactPaginate
                            breakLabel="..."
                            nextLabel={<i className="fa fa-angle-right" aria-hidden="true"/>}
                            onPageChange={this.handlePageChange}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            initialPage={page - 1}
                            previousLabel={<i className="fa fa-angle-left" aria-hidden="true"/>}
                            // @ts-ignore
                            renderOnZeroPageCount={null}
                            containerClassName="pagination"
                            pageClassName="page-item"
                            previousClassName="page-item"
                            nextClassName="page-item"
                            breakClassName="page-item"
                            pageLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            breakLinkClassName="page-link"
                            activeLinkClassName="page-link-active"
                        />

                    </div>
                </section>


            </div>

        </div>
    }
};
