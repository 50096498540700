import {BaseApi, BaseResponse} from './BaseApi'


class UserApi extends BaseApi {

    public async getId(): Promise<BaseResponse> {
        return this.getRequest('/getuserid')
    }

    public async sendFeedback(name?: string, email?: string, company?: string, message?: string): Promise<BaseResponse> {
        return this.postRequest('/sendcontact', {name, email, company, message})
    }

}

export default new UserApi()
