import React from 'react'
import './App.css'

import {BrowserRouter, Route, Switch} from 'react-router-dom'
import MainPage from './pages/MainPage'
import SupportsPage from './pages/SupportsPage'
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import SupportPage from './pages/SupportPage'
import routes from './routes/routes'
import ContactsPage from './pages/ContactsPage'
import NotFoundPage from './pages/NotFoundPage'
import InstitutesPage from './pages/InstitutesPage'
import RulesPage from './pages/RulesPage'
import InstitutePage from './pages/InstitutePage'
import NewsPage from './pages/NewsPage'
import NewsDetailedPage from './pages/NewsDetailedPage'
import StaticPage from './pages/StaticPage'

toast.configure({
    autoClose: 8000,
})

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Switch>
                    <Route exact path={routes.mainPage.path} component={MainPage}/>
                    <Route exact path={routes.supportsPage.path} component={SupportsPage}/>
                    <Route exact path={routes.supportPage.path} component={SupportPage}/>
                    <Route exact path={routes.contactsPage.path} component={ContactsPage}/>
                    <Route exact path={routes.institutesPage.path} component={InstitutesPage}/>
                    <Route exact path={routes.institutePage.path} component={InstitutePage}/>
                    <Route exact path={routes.rulesPage.path} component={RulesPage}/>
                    <Route exact path={routes.newsPage.path} component={NewsPage}/>
                    <Route exact path={routes.newsDetailedPage.path} component={NewsDetailedPage}/>
                    <Route exact path={routes.newsPageBase.path} component={NewsPage}/>
                    <Route exact path={routes.notFoundPage.path} component={NotFoundPage}/>
                    <Route exact path={routes.staticPage.path} component={StaticPage}/>
                    <Route component={NotFoundPage}/>
                </Switch>
            </BrowserRouter>
        </div>
    )
}

export default App
